import React, { useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import { css } from '@emotion/react'

const JobCard = (props) => {
    const {
        id,
        index,
        name,
        diploma,
        description,
        image,
    } = props

    const [open, setOpen] = useState(false)

    const prettyDesc = (isOpen) => {
        let maxLength = 150

        return !isOpen && description.length > maxLength ?
            <p>{description.substr(0, maxLength) + "..."} <button onClick={() => setOpen(!open)}>[voir plus]</button></p>
            :
            <p>{description} {open && <button onClick={() => setOpen(!open)}>[réduire]</button>}</p>
    }

    return (
        <div key={id} css={card}>
            {image && <GatsbyImage image={image} alt={name} css={cover}/>}

            <div css={numbering}>{index}</div>

            <div css={main}>
                <h2>{name}</h2>

                {diploma && <div css={meta}>{diploma.join(", ")}</div>}

                {description && <div>{prettyDesc(open)}</div>}
            </div>
        </div>
    )
}

export default JobCard

const card = css`
  border: 1px solid #EBEBEB;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
`

const numbering = css`
  position: absolute;
  font-size: 100px;
  font-weight: 900;
  line-height: 1;
  opacity: .11;
  left: -10px;
  top: -20px;
`

const meta = css`
  opacity: .66;
  font-size: 16px;
`

const cover = css`
    height: 200px;
    width: 100%;
`

const main = css`
    padding: 20px;

    h2 {
        margin: 0;
        font-size: 1rem;
    }

    p {
        margin: 5px 0 0;
        font-size: .8rem;
        line-height: 1.3;
    }

    button {
        border: none;
        background: none;
        font-size: .8rem;
        padding: 0;
        cursor: pointer;
        opacity: .66;
        transform: opacity 1s ease-in-out;

        &:hover {
            color: #00ffaa;
            opacity: 1;
        }
    }
`