import React from "react"
import { graphql } from 'gatsby'
import Container from "../components/container"

import { css } from '@emotion/react'

import Layout from "../components/layout"
import Seo from "../components/seo"
import JobCard from "../components/jobCard"
import Button from "../components/button"

import { FaTools, FaMapSigns, FaCarBattery, FaBoxes, FaCarSide } from "react-icons/fa"
import { GiConcreteBag, GiPineTree, GiRecycle, GiTruck, GiOpenedFoodCan, GiSewingMachine } from "react-icons/gi"
import { MdTrain, MdAgriculture, MdOutlineAirplanemodeActive, MdOutlineHouse, MdDirectionsBoatFilled } from "react-icons/md"
import { BsBicycle } from "react-icons/bs"
import { BiCycling } from "react-icons/bi"


const JobsPage = ( {data} ) => {
  const jobs = data.allAirtable.edges.map( edge => edge.node )
  const jobCount = data.allAirtable.totalCount

  const positiveColor = "rgba(0, 255, 170, 1)"
  const positiveColorTamed = "rgba(0, 255, 170, .11)"
  const negativeColor = "rgba(245, 77, 186, 1)"
  const negativeColorTamed = "rgba(245, 77, 186, .11)"

  return (
    <Layout>
      <Seo title="Jobs à impact pour la transition énergétique et sociale" />
  
      <div style={{marginTop: "70px"}}>
        <Container>
          <h1>Les métiers de la transition</h1>
          <p>Le facteur humain, souvent mis de côté au profit de l'élément "technologique", est un facteur clé de réussite de la transition écologique et sociale. Les enjeux mobilisent tous les métiers et les secteurs, et ne se limitent pas aux métiers "verts" ou sociaux, aux secteurs innovants ou "carbonés". Il s'agit bien de faire évoluer les emplois tout comme les compétences et savoir-faire, ce qui implique une anticipation non négligeable.</p>
          <p>Pour répondre aux défis de la transition écologique, économique, sociale et solidaire, de nombreux métiers devront se transformer, d'autres seront amenés à disparaître ou être créés pour répondre aux nouveaux besoins.</p>
          <Button link="#selection" text="Sélection d'emplois" svg={<FaTools />} style={{"marginRight": "20px"}}/>
          <Button link="#tendances" text="Les tendances" svg={<FaMapSigns />} alt />

          <div id="selection" style={{"marginTop": "50px"}}>
            <h2>{jobCount} Emplois et formations au coeur de la transition énergétique et sociale</h2>
            <p>Nous travaillons à développer cette liste des "jobs du futur" à mesure que les estimations des économistes s'affinent quant au manque de main d'oeuvre dans les secteurs les plus au coeur de la transition.</p>

            <div css={grid}>
              {jobs.map( (job, index) => {
                // const img = job.data.Cover ? job.data.Cover[0].thumbnails.large.url : null

                return (
                  <JobCard 
                    id={job.id}
                    index={index+1}
                    name={job.data.Name}
                    diploma={job.data.Diploma}
                    description={job.data.Description}
                    image={job.data.Cover && job.data.Cover.localFiles[0].childImageSharp.gatsbyImageData}
                  />
                )
              })}
            </div>
          </div>

          <div id="tendances" css={trendSection}>
            <h2>Les tendances de l'emploi de la transition énergétique et sociale</h2>
            <p>Les statistiques ci-dessous donnent, pour les secteurs prioritairement concernés par la transition, une estimation des tendances d'augmentation<span className="trend-badge" style={{"backgroundColor": positiveColor}}></span> ou de disparition<span className="trend-badge" style={{"backgroundColor": negativeColor}}></span> d'emplois - ceci en fonction des choix politiques effectués pour anticiper et réagir aux contraintes d'une économie en transition.</p>

            <div css={statsGrid}>
              {sectorStats.sort( (a, b) => b.delta - a.delta ).map( sector => (
                <div css={statsCard} key={sector.name}>
                  <div 
                    className="stats-card__main" 
                    style={sector.delta > 0 ? {"backgroundColor": positiveColorTamed} : {"backgroundColor": negativeColorTamed}}
                  >
                    {sector.icon && 
                      <div 
                        className="stats-card__icon" 
                        style={sector.delta > 0 ? {"backgroundColor": positiveColor} : {"backgroundColor": negativeColor}}>
                        {sector.icon}
                      </div>
                    }
                    <h3>{sector.name}</h3>
                  </div>

                  <div className="stats-card__data">
                    <div className="stats-card__data__holder">
                      <div className="stats-card__data__graph"
                        style={{
                          "width": `${Math.abs(sector.delta)}px`,
                          "backgroundColor": sector.delta > 0 ? positiveColor : negativeColor,
                          "marginLeft": sector.delta > 0 ? "50%" : `calc(50% - ${Math.abs(sector.delta)}px)`,
                        }}
                      ></div>
                    </div>
                    <div className="stats-card__data__origin"></div>
                  </div>
                </div>
              ))}
            </div>

            {false && <div css={sources}>
              <h4>Sources</h4>
              <ul>
                <li>Plan de transformation de l'économie française</li>
              </ul>
            </div>}
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default JobsPage

const grid = css`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 30px 0 80px;
  gap: 20px;

  @media screen and (max-width: 1300px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`

const trendSection = css`
  .trend-badge {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: black;
    display: inline-block;
    margin-left: 3px;
    margin-right: 5px;
  } 
`

const statsGrid = css`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
  margin-bottom: 30px;

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const statsCard = css`
  border: 1px solid #EBEBEB;
  border-radius: 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;

  .stats-card__main {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      font-size: .8rem;
      margin: 0;
    }

    .stats-card__icon {
      height: 50px;
      width: 50px;
      padding: 10px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
    }
  }

  .stats-card__data {
    padding: 20px;
    position: relative;

    .stats-card__data__holder {
      background-color: #EFEFEF;
      border-radius: 50px;
      width: 100%;
      height: 20px;
      overflow: hidden;

      .stats-card__data__graph {
        height: 100%;
      }
    }
    .stats-card__data__origin {
      position: absolute;
      height: 50%;
      width: 1px;
      background-color: #333;
      top: 25%;
      left: 50%;
    }
  }
`

const sources = css`
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 5px;
  // background-color: #D1BAA0;

  h4 {
    margin-bottom: 10px;
  }

  ul {
    margin-bottom: 0;
  }
`


export const jobsQuery = graphql`
  query JobsQuery {
    allAirtable(filter: {table: {eq: "Jobs"}, data: {Status: {eq: "Published"}}}, sort: {fields: data___Name, order: ASC}) {
      edges {
        node {
          id
          data {
            Name
            Description
            Diploma
            Verticals {
              id
              data {
                Name
              }
            }
            Cover {
              id
              localFiles {
                childImageSharp {
                  gatsbyImageData(
                      width: 500
                      quality: 80
                  )
                }
              }
            }
          }
        }
      }
      totalCount
    }
  }
`

const sectorStats = [
  {
    "name": "Agroalimentaire", 
    "delta": -10,
    "icon": <GiOpenedFoodCan />,
  },
  {
    "name": "Ciment et béton", 
    "delta": -2,
    "icon": <GiConcreteBag />,
  },
  {
    "name": "Construction neuve", 
    "delta": -30,
    "icon": <MdOutlineHouse />,
  },
  {
    "name": "Industrie automobile", 
    "delta": -40,
    "icon": <FaCarSide />,
  },
  {
    "name": "Transport aérien", 
    "delta": -6,
    "icon": <MdOutlineAirplanemodeActive />,
  },
  {
    "name": "Transport routier", 
    "delta": -20,
    "icon": <GiTruck />,
  },
  {
    "name": "Fret vélo", 
    "delta": +18,
    "icon": <BiCycling />,
  },
  {
    "name": "Fret ferroviaire", 
    "delta": +2,
    "icon": <FaBoxes />,
  },
  {
    "name": "Fret fluvial", 
    "delta": +2,
    "icon": <MdDirectionsBoatFilled />,
  },
  {
    "name": "Transport ferroviaire longue distance", 
    "delta": +6,
    "icon": <MdTrain />,
  },
  {
    "name": "Industrie vélo", 
    "delta": +30,
    "icon": <BsBicycle />,
  },
  {
    "name": "Batteries & recharge", 
    "delta": +10,
    "icon": <FaCarBattery />,
  },
  {
    "name": "Rénovation", 
    "delta": +20,
    "icon": <GiRecycle />,
  },
  {
    "name": "Forêt et bois", 
    "delta": +5,
    "icon": <GiPineTree />,
  },
  {
    "name": "Production agricole", 
    "delta": +20,
    "icon": <MdAgriculture />,
  },
  {
    "name": "Artisanat commercial", 
    "delta": +30,
    "icon": <GiSewingMachine />,
  },
]