import React from "react"
import { Link } from "gatsby"

import { css } from '@emotion/react'

const Button = (props) => {
    const {
        link,
        text,
        svg,
        external,
        alt
    } = props


    const linkWrapper = (children) => {
        if ( external ) {
            return (
                <a href={link} target="_blank" rel="nofollow noopener noreferrer" css={alt ? [main, altStyle] : main}>
                    {children}
                </a>
            )
        } else {
            return (
                <Link to={link} css={alt ? [main, altStyle] : main} {...props} >
                    {children}
                </Link>
            )
        }
    }

    return linkWrapper(<span>{svg}{text}</span>)
}

export default Button

const main = css`
    background-color: #00ffaa;
    padding: 20px 20px;
    border-radius: 5px;
    text-decoration: none;
    color: black;
    transition: background-color .2s ease-in-out;
    line-height: 1;
    display: inline-block;

    &:hover {
        background-color: black;
        color: white;
    }

    svg {
        margin-bottom: -3px;
        margin-right: 10px;
    }
`

const altStyle = css`
    background-color: #333;
    color: white;

    &:hover {
        background-color: #D1BAA0;
        color: #333;
    }
`